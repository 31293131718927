export default function SlideHome() {
  return (
    <div>
      <div className="image-background"></div>
      <div className=" h-screen flex items-center flex-col text-blue-200 z-10 relative testColors ">
        <div className="h-[20vh] my-12 flex items-center justify-center">
          <p className="text-[6vh] md:text-[8vh] lg:text-[10vh] font-chinoise  ">
            GALA de l'ENIB
          </p>
        </div>
        <div className="h-[15vh] -my-24 flex items-center justify-center">
          <p className="text-[4vh] md:text-[6vh] lg:text-[8vh] font-chinoise  ">
            58 ème édition
          </p>
        </div>
        <div className="h-40 flex items-center justify-center ">
          <div className=" logoImg mt-[50vh]"></div>
        </div>
      </div>
    </div>
  );
}
