import "../components/Button.css";

export default function SlideProg() {
  return (
    <div className="carousel-content bg-black h-screen flex items-center justify-center containers font">
      <div className=" container-info  h-screen">
        <div className="flex justify-center flex-col">
          <div className="flex items-center justify-center flex-col">
            <p className="text-blue-100 text-3xl pt-4 underline underline-offset-8 font-bold ">
              Programme
            </p>
            <p className="text-blue-100 text-xl mt-[2vh] font-bold flex items-center text-center    ">
              13 avril 2024
            </p>
          </div>

          <div className="flex items-center justify-center text-center mt-3">
            <ul className=" text-blue-200  text-xl  ">
              <li>Début de la remise des diplômes</li>
              <li className="py-4 font-bold">15h30 - 17h30</li>
              <li className="border-t-2 border-blue-100   w-60 mx-auto bottom-0 left-0 right-0 pt-8"></li>
              <li>Cocktail offert par l'administration</li>
              <li className="py-4 font-bold">17h30 - 18h45</li>
              <li className="border-t-2 border-blue-100  w-60 mx-auto bottom-0 left-0 right-0 pt-8"></li>
              <li>Accueil pour le repas</li>
              <li className="py-4 font-bold">18h45 - 21h</li>
              <li className="border-t-2 border-blue-100  w-60 mx-auto bottom-0 left-0 right-0 pt-8"></li>
              <li>Début de soirée</li>
              <li className="py-4 font-bold">21h - 1H</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="bg-blue-100  white-bar"></div>
      <div className=" container-info  h-screen">
        <div className="flex justify-center flex-col">
          <div className="flex items-center justify-center">
            <p className="text-blue-100 text-3xl pt-4 underline underline-offset-8 font-bold ">
              Menu
            </p>
          </div>
          <div className="flex items-center justify-center text-center ">
            <ul className="pt-[7vh] text-blue-200  text-xl mx-10 ">
              <li className="font-bold">Entrée</li>
              <li className="pt-4 pb-8 text-lg ">
                Chair de Crabe Façon Nem, Crème de Carotte de Sable au Paprika
                Fumé, Jus de Carcasse au Kari Goss
              </li>
              <li className="border-t-2 border-blue-100 w-60 mx-auto bottom-0 left-0 right-0 pt-3"></li>
              <li className="font-bold">Plat </li>
              <li className="pt-3 pb-8 text-lg">
                Suprême de Volaille de Kercoat, Farce Fine aux Eclats de
                Pistaches, Carottes Rôties Ou Canette Jaune Grillée, Condiment
                d’Airelles, Jus de Porto
              </li>
              <li className="border-t-2 border-blue-100  w-60 mx-auto bottom-0 left-0 right-0 pt-3"></li>
              <li className="font-bold">Entrée </li>
              <li className="pt-3  text-lg">Mi-cuit au chocolat</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
