import React from "react";
import "../styles/ProfilCard.css";
const ProfilCard = ({ photo, nom, role }) => {
  return (
    <div className="flat">
      <div className=" flex flex-col items-center">
        <img src={photo} alt={nom} className="profil-photo rounded-t-lg " />
        <div className=" flex flex-col items-center ">
          <h3 className="text-[3vh] md:text-[2vh] lg:text-[2.5vh] font-bold">
            {nom}
          </h3>
          <p className="text-[1vh] md:text-[3vh] lg:text-[2vh]">{role}</p>
        </div>
      </div>
    </div>
  );
};

ProfilCard.propTypes = {};

export default ProfilCard;
